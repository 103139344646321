@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";




@import url('fonts/fonts.css');

* {
  font-family: 'SFPro', sans-serif;
  margin: 0;
  padding: 0;
}

/* #js-licensing{
  display: none;
} */

html,
body,
#root,
.App {
  height: 100%;
}

body {
  font-family: 'SFPro', sans-serif;
  font-style: normal;
  font-weight: normal;
}

:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: transparent;
}

*::-webkit-scrollbar {
  /* display: initial; */
  /* overflow: scroll; */
}

a {
  all: unset;
  cursor: pointer;
}


.rs-picker-menu{
  z-index: 9999 !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn{
  height: 57px !important;
}

.infinite-scroll-component__outerdiv{
  height: 100%;
  width: 100%;
  
}
.infinite-scroll-component__outerdiv:first-child {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.tui-image-editor-header {
  display: none;
}
.tui-image-editor-controls-buttons {
  display:none !important;
}

.tui-colorpicker-palette-container .tui-colorpicker-clearfix:first-child{
  display: flex;
  flex-wrap: wrap;
}
.tui-colorpicker-palette-container .tui-colorpicker-clearfix:first-child li{
  width:20px;
  height:20px;
  margin:3px;

}
.tui-colorpicker-palette-container .tui-colorpicker-clearfix:first-child li input{
  width:20px;
  height:20px;

}
.tui-colorpicker-palette-preview{
  color:transparent !important;
}

.eLItJu {
  z-index: 1301 !important;
}

.rdw-colorpicker-modal {
  left: -80px !important;
}

@media screen and (max-width: 820px) {
  #uploadToGalleryBtn {
    position: fixed;
    bottom: -4px;
    width: 100%;
    left: 0;
    top: unset;
    right: unset;
    margin-right: 0;
    height: 48px;
  }
  #exportToGalleryBtn {
    position: fixed;
    bottom: 32px;
    width: 100%;
    left: 0;
    top: unset;
    right: unset;
    margin-right: 0;
    height: 48px;
  }
}

@media screen and (max-width: 600px) {
  .e-quick-popup-wrapper.e-device.e-lib.e-popup.e-control.e-popup-open {
    top: 50px !important;
    padding-top: 18px !important;
  }
}

.radio-button {
    border: 1px solid #f44336;
    min-width: 16px !important;
    height: 16px;
    border-radius: 50%;
    position: relative;
}

.radio-button > div {
  display: none;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f44336;
}

.download-select svg {
  color: rgba(0, 0, 0, 0.54)
}

.radio-button.selected > div {
  display: block;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  left: 274px !important;
}


_::-webkit-full-page-media, _:future, :root .add-insp-btn {
    bottom: -30px;
}

_::-webkit-full-page-media, _:future, :root .add-insp-btn--tablet {
    bottom: -30px;
}

@media screen and (max-width: 702px) {
  _::-webkit-full-page-media, _:future, :root .add-insp-btn--tablet {
    bottom: 0px;
  }
}

_::-webkit-full-page-media, _:future, :root .add-insp-btn--mobile {
  bottom: 0px;
}


@media (min-width:1280px) and (max-width:1340px) {
  .comment-date-picker .DayPicker-Week,
  .comment-date-picker .DayPicker-WeekdaysRow{
    max-width: 280px; 
    margin-left: 4px;
  }
}