@font-face {
  font-family: 'SFPro';
  src: local('SFPro Light'), local('SFPro-Light'), url('./SFProDisplay-Light.woff2') format('woff2'),
    url('./SFProDisplay-Light.woff') format('woff'), url('./SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: local('SFPro Regular'), local('SFPro-Regular'), url('./SFProDisplay-Regular.woff2') format('woff2'),
    url('./SFProDisplay-Regular.woff') format('woff'), url('./SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: local('SFPro Medium'), local('SFPro-Medium'), url('./SFProDisplay-Medium.woff2') format('woff2'),
    url('./SFProDisplay-Medium.woff') format('woff'), url('./SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFPro';
  src: local('SFPro Bold'), local('SFPro-Bold'), url('./SFProDisplay-Bold.woff2') format('woff2'),
    url('./SFProDisplay-Bold.woff') format('woff'), url('./SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: local('Roboto Mono Regular'), local('RobotoMono-Regular'),
    url('./RobotoMono-Regular.eot') format('embedded-opentype'), url('./RobotoMono-Regular.woff') format('woff'),
    url('./RobotoMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
